.table-container {
  width: 100%;

  table {
    width: 100%;

    th:hover {
      color: var(--hsl-theme-primary);
    }

    tbody tr {
      &.can-expand { background-color: var(--hsl-theme-light); }
      &.subrow { background-color: white; }
    }

    td, th {
      &.attempts,
      &.completed,
      &.certificates,
      &.score {
        text-align: right;
      }
    }

    td {
      &.attempts,
      &.completed,
      &.certificates,
      &.score {
        padding-right: 20px !important;
      }
    }

    td {
      max-width: 240px;
      
      &.label {
        max-width: 340px;
      }

      .indent {
        width: 300px;
      }

      &.selected > button {
        color: var(--hsl-theme-primary);
        font-weight: var(--font-weight-medium);
      }
    }

    button {
      min-width: 100px;
      min-height: 15px;
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      display: block;
      text-align: left;
      background-color: transparent;
      color: var(--hsl-text);
      font-size: var(--font-size-s);
      font-family: var(--font-family-regular);
      font-weight: var(--font-weight-regular);
      transition: color .15s ease-out,
                  background-color .15s ease-out;
      &:hover {
        color: var(--hsl-theme-primary);
      }
    }
  }

}



.pagination {
  margin: 16px 0 0;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;

  > nav {
    display: flex;
    align-items: center;
    > button { margin: 0 2px; }
  }

  > div {
    font-size: var(--font-size-xs);
    display: flex;
    align-items: center;
    margin: 0 10px;
  }
}