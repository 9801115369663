/* Reset */
*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: inherit;
}

html { box-sizing: border-box; }

body {
	-moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
	-webkit-text-size-adjust: none;
}

html, body { height: 100%; }



/* Variables */
:root {
  color-scheme: light dark;
  
  --theme-primary-h:   210;
  --theme-primary-s:   100%;
  --theme-primary-l:   50%;
  --theme-primary:     var(--theme-primary-h), var(--theme-primary-s), var(--theme-primary-l);
  --hsl-theme-primary: hsl( var(--theme-primary-h), var(--theme-primary-s), var(--theme-primary-l) );

  --hsl-text:       hsl( var(--theme-primary-h), 100%, 17% );
  --hsl-background: hsl( var(--theme-primary-h), 50%, 97% );
  --hsl-white:      hsl( var(--theme-primary-h), 0%, 100% );
  --hsl-black:      hsl( var(--theme-primary-h), 0%, 0% );

  --hsl-grey-lighter: hsl( 210, 41%, 90% );
  --hsl-grey-light:   hsl( 210, 35%, 86% );
  --hsl-grey-dark:    hsl( 210, 29%, 72% );
  --hsl-grey-darker:  hsl( 210, 19%, 63% );

  --hsl-red:    hsl( var(--red) );
  --hsl-orange: hsl( var(--orange) );
  --hsl-yellow: hsl( var(--yellow) );
  --hsl-green:  hsl( var(--green) );
  --hsl-blue:   hsl( var(--blue) );
  --hsl-purple: hsl( var(--purple) );
  --hsl-pink:   hsl( var(--pink) );

  --red:      var(--red-h), 100%, var(--red-l);
  --orange:   var(--orange-h), 100%, var(--orange-l);
  --yellow:   var(--yellow-h), 100%, var(--yellow-l);
  --green:    var(--green-h), 100%, var(--green-l);
  --blue:     var(--blue-h), 100%, var(--blue-l);
  --purple:   var(--purple-h), 100%, var(--purple-l);
  --pink:     var(--pink-h), 100%, var(--pink-l);

  --red-h:    0;
  --orange-h: 41;
  --yellow-h: 41;
  --green-h:  83;
  --blue-h:   192;
  --purple-h: 273;
  --pink-h:   310;

  --red-l:    60%;
  --orange-l: 50%;
  --yellow-l: 70%;
  --green-l:  40%;
  --blue-l:   50%;
  --purple-l: 60%;
  --pink-l:   65%;
  

  --font-family-regular:  "Source Sans Pro", sans-serif;
  --font-family-medium:   "Source Sans Pro", sans-serif;
  --font-family-bold:     "Source Sans Pro", sans-serif;
  --font-family-italic:   "Source Sans Pro", sans-serif;

  --font-weight-regular:  400;
  --font-weight-medium:   600;
  --font-weight-bold:     700;

  --font-weight-italic:   400;
  --font-style-italic:    italic;

  --lineheight:           1.333;

  --font-size-xxl:        26px;
  --font-size-xl:         22px;
  --font-size-l:          18px;
  --font-size-m:          16px;
  --font-size-s:          14px;
  --font-size-xs:         12px;
  --font-size-xxs:        11px;
}



/* Body */
body {
  font-family: var(--font-family-regular);
  font-weight: var(--font-weight-regular);
  background-color: var(--hsl-background);
  color: var(--hsl-text);
  font-size: var(--font-size-m);
}
::selection {
  color: var(--hsl-white);
  background: var(--hsl-theme-primary);;
}



/* Text */

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-family-bold);
  font-weight: var(--font-weight-bold);
  line-height: var(--lineheight);
}

h1 { font-size: var(--font-size-xxl); }
h2 { font-size: var(--font-size-xl); }
h3 { font-size: var(--font-size-l); }
h4 { font-size: var(--font-size-m); }
h5 { font-size: var(--font-size-s); }
h6 { font-size: var(--font-size-xs); }

h1 + p { font-size: var(--font-size-xl); }
h2 + p { font-size: var(--font-size-l); }
h3 + p { font-size: var(--font-size-m); }
h4 + p { font-size: var(--font-size-s); }
h5 + p { font-size: var(--font-size-xs); }
h6 + p { font-size: var(--font-size-xxs); }

p, span, blockquote {
  line-height: var(--lineheight);
}

strong, b {
  font-family:  var(--font-family-bold);
  font-weight:  var(--font-weight-bold);
}

em, i {
  font-family:  var(--font-family-italic);
  font-weight:  var(--font-weight-italic);
  font-style:   var(--font-style-italic);
}

blockquote, q {
  font-family:  var(--font-family-italic);
  font-weight:  var(--font-weight-italic);
  font-style:   var(--font-style-italic);
  font-size:    var(--font-size-l);
}
blockquote::before, q::before { content: open-quote; }
blockquote::after, q::after { content: close-quote; }

.font-size-xxs { font-size: var(--font-size-xxs); }
.font-size-xs  { font-size: var(--font-size-xs); }
.font-size-s   { font-size: var(--font-size-s); }
.font-size-m   { font-size: var(--font-size-m); }
.font-size-l   { font-size: var(--font-size-l); }
.font-size-xl  { font-size: var(--font-size-xl); }
.font-size-xxl { font-size: var(--font-size-xxl); }



/* Lists */

ul, ol { margin-left: 28px; }

ul > li::marker {
  color: var(--hsl-theme-primary);
}

ol > li::marker {
  color: var(--hsl-theme-primary);
  font-family: var(--font-family-medium);
  font-weight: var(--font-weight-medium);
}

ol > li > ol { list-style: lower-alpha; }



/* Hyperlinks */
a {
  color: var(--hsl-theme-primary);
  font-family: var(--font-family-medium);
  font-weight: var(--font-weight-medium);
}
a:hover { text-decoration: none; }