.card {
	position: relative;
	max-width: 100%;
	border-radius: 7px;
	background-color: var(--hsl-white);
	box-shadow: 0 1px 4px HSLA( var(--theme-primary-h), 100%, 17%, .12 );
	padding: 16px 20px;

	@media screen and (min-width: 768px) {
		padding: 20px 24px;
	}
}