body {
  user-select: none;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lrs-dashboard {
  max-width: 1800px;
  width: 100%;
  padding: 24px;
}

.lrs-dashboard > header {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-bottom: 24px;

  h1, h2 {
    font-size: var(--font-size-xl);
    + p {
      font-size: var(--font-size-m);
    }
  }

  .left { margin-bottom: 16px; }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;

    .left {
      text-align: left;
      margin-bottom: 0;
    }
    .right { text-align: right; }
  }
}



.filter {

  > div { margin-bottom: 8px; }

  .flex-parent {
    display: flex;
    flex-direction: column;
    @media screen and (min-width: 1024px) {
      flex-direction: row;
    }
  }

  .col {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 8px;

    @media screen and (min-width: 1024px) {
      &:nth-child(2),
      &:nth-child(3) {
        padding-left: 8px;
      }
    }
  }

  .include-exclude {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    min-width: 78px;
    max-width: 78px;
    background-color: white;
    border: 1px solid var(--hsl-grey-light);
    border-radius: 5px;
    margin-right: 5px;
    button:nth-of-type(1) { margin-right: 2px; }
    button:nth-of-type(2) { margin-left: 2px; }
  }

  .multi-select-container {
    width: 100%;
  }
}



.divider {
  width: calc(100% + 48px);
  margin: 12px 0 20px -24px;
  height: 1px;
  background-color: var(--hsl-grey-lighter);
}



.tab-nav-container {
  position: relative;
  display: flex;
  flex-direction: column-reverse;

  .tab-nav {
    width: 100%;
    margin-bottom: 8px;
  }

  button.datepicker { margin-bottom: 16px; }

  @media screen and (min-width: 768px) {
    flex-direction: row;
    button.datepicker {
      min-width: 260px;
      margin: 0 0 0 16px;
    }
  }
}






// Content

.card {
  position: relative;
  transition: min-height .5s ease;

  .loading-spinner { border-radius: 7px; }

  h4 {
    text-align: center;
    margin: 4px 0 16px;
  }

  &.coursetype { min-height: 309px; }

  &.chart { min-height: 278px; }

  .chart {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 200px;
    transition: height .5s ease;
  }

  &.total {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100px;

    @media screen and (min-width: 1024px) {
      min-height: 136px;
    }
  }

  > .total {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family-bold);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-l);

    > div {
      display: flex;
      align-items: center;
      > .number {
        font-size: var(--font-size-xxl);
        margin: 0 4px 0 12px;
      }
    }

    > .label {
      font-size: var(--font-size-m);
      white-space: nowrap;
    }

  }

  &.table {
    > span { text-align: center; display: block; }

    .data-table {
      display: flex;
      flex-direction: column;
    }
    th.min_timestamp,
    td.min_timestamp {
      display: none;
    }
    .show-mindate {
      th.min_timestamp,
      td.min_timestamp {
        display: table-cell;
      }
    }
  }
}


button.csv {
  align-self: center;
  margin: 16px 0 0;

  @media screen and (min-width: 768px) {
    align-self: flex-start;
    margin-top: -24px;
  }

  .csv-link {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}





// Date Picker

.datepicker-container {
  z-index: 1;
  position: absolute;
  top: 58px;
  right: 0;
  box-shadow: 0 4px 8px HSLA( var(--theme-primary-h), 100%, 17%, .16 );
  border-radius: 7px;
  overflow: hidden;
}

.rdrStaticRange { color: black; }
.rdrInputRanges { display: none; }
.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  font-weight: var(--font-weight-bold) !important;
}

@keyframes enterDatePicker {
	0% {
    opacity: 0;
    transform: translateY(-3%);
  }
	100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes exitDatePicker {
	0% {
    opacity: 1;
    transform: translateY(0);
  }
	100%  {
    opacity: 0;
    transform: translateY(-3%);
  }
}