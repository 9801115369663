// Modal

.modal-container .modal-content main {
  align-items: inherit !important;
}

.selected-question {
  position: relative;
  margin-bottom: 16px;

  &:not(:empty) {
    background: var(--hsl-background);
    border-radius: 7px;
    padding: 16px;
    min-height: 100px;
  }

  p {
    white-space: pre-line;
    font-family: var(--font-family-bold);
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-m);
    border-radius: 9px;
    background-color: white;
    padding: 8px 12px;
    margin-bottom: 12px;
  }

  ul {
    list-style: none;
    margin-left: 0;
    font-size: var(--font-size-s);
    background-color: white;
    border-radius: 7px;

    li {
      display: flex;
      padding: 6px;

      .icon { margin: 4px 6px 0; }

      &:not(:last-child) {
        border-bottom: 1px solid var(--hsl-background);
      }

      &.correct { color: var(--hsl-green); }
      &.wrong { color: var(--hsl-red); }

    }
  }

  > .loading-spinner.mask {
    border-radius: 7px;
  }
}