// Icon Sizes

.icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  box-shadow: inset 0 0 0 0 transparent;
  transition: all .25s ease;

  svg {
    width: 100%;
    height: 100%;
    fill: black;
    transition: all .25s ease;
  }

  &.size-xs {
    width: 12px;
    min-width: 12px;
    height: 12px;
    padding: 2px;
  }
  
  &.size-s {
    width: 16px;
    min-width: 16px;
    height: 16px;
    padding: 3px;
  }

  &.size-m {
    width: 20px;
    min-width: 20px;
    height: 20px;
    padding: 4px;
  }

  &.size-l {
    width: 24px;
    min-width: 24px;
    height: 24px;
    padding: 5px;
  }

  &.size-xl {
    width: 32px;
    min-width: 32px;
    height: 32px;
    padding: 6px;
  }

  &.size-xxl {
    width: 40px;
    min-width: 40px;
    height: 40px;
    padding: 7px;
  }

  &.size-responsive {
    width: 20px;
    min-width: 20px;
    height: 20px;
    padding: 4px;
    @media screen and (min-width: 768px) {
      width: 24px;
      min-width: 24px;
      height: 24px;
      padding: 5px;
    }
  }
}



// Icon Shapes

.icon {

  &.shape-circle { border-radius: 50%; }
  &.shape-rounded { border-radius: 5px; }
  &.shape-square { border-radius: 0; }

}



// Icon Styles and Colors

.icon {

  &.variant-flat {
    background-color: black;
    svg { fill: var(--hsl-white); }

    &.color-primary { background-color: var(--hsl-theme-primary); }
    &.color-default { background-color: var(--hsl-text); }
    &.color-red     { background-color: var(--hsl-red); }
    &.color-orange  { background-color: var(--hsl-orange); }
    &.color-yellow  { background-color: var(--hsl-yellow); }
    &.color-green   { background-color: var(--hsl-green); }
    &.color-blue    { background-color: var(--hsl-blue); }
    &.color-purple  { background-color: var(--hsl-purple); }
    &.color-pink    { background-color: var(--hsl-pink); }
    &.color-grey    { background-color: var(--hsl-grey-light); }
    &.color-white   {
      background-color: var(--hsl-white);
      svg { fill: black; }
    }
  }

  &.variant-ghost {
    background-color: transparent;
    box-shadow: inset 0 0 0 1px black;
    svg { fill: black; }

    &.color-primary {
      box-shadow: inset 0 0 0 1px var(--hsl-theme-primary);
      svg { fill: var(--hsl-theme-primary); }
    }
    &.color-default {
      box-shadow: inset 0 0 0 1px var(--hsl-text);
      svg { fill: var(--hsl-text); }
    }
    &.color-red {
      box-shadow: inset 0 0 0 1px var(--hsl-red);
      svg { fill: var(--hsl-red); }
    }
    &.color-orange {
      box-shadow: inset 0 0 0 1px var(--hsl-orange);
      svg { fill: var(--hsl-orange); }
    }
    &.color-yellow {
      box-shadow: inset 0 0 0 1px var(--hsl-yellow);
      svg { fill: var(--hsl-yellow); }
    }
    &.color-green {
      box-shadow: inset 0 0 0 1px var(--hsl-green);
      svg { fill: var(--hsl-green); }
    }
    &.color-blue {
      box-shadow: inset 0 0 0 1px var(--hsl-blue);
      svg { fill: var(--hsl-blue); }
    }
    &.color-purple {
      box-shadow: inset 0 0 0 1px var(--hsl-purple);
      svg { fill: var(--hsl-purple); }
    }
    &.color-pink {
      box-shadow: inset 0 0 0 1px var(--hsl-pink);
      svg { fill: var(--hsl-pink); }
    }
    &.color-grey {
      box-shadow: inset 0 0 0 1px var(--hsl-grey-light);
      svg { fill: var(--hsl-grey-light); }
    }
    &.color-white {
      box-shadow: inset 0 0 0 1px var(--hsl-white);
      svg { fill: var(--hsl-white); }
    }
  }

  &.variant-secondary {
    background: transparent;

    &.color-primary svg { fill: var(--hsl-theme-primary); }
    &.color-default svg { fill: var(--hsl-text); }
    &.color-red svg     { fill: var(--hsl-red); }
    &.color-orange svg  { fill: var(--hsl-orange); }
    &.color-yellow svg  { fill: var(--hsl-yellow); }
    &.color-green svg   { fill: var(--hsl-green); }
    &.color-blue svg    { fill: var(--hsl-blue); }
    &.color-purple svg  { fill: var(--hsl-purple); }
    &.color-pink svg    { fill: var(--hsl-pink); }
    &.color-grey svg    { fill: var(--hsl-grey-light); }
    &.color-white svg   { fill: var(--hsl-white); }
  }
}