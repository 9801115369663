// Data Tables

.table-container {
  padding-bottom: 16px;
  overflow: auto;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  table.fixed-header {
    width: 100%;
    thead th {
      position: sticky;
      top: 0;
    }
    &.header-minimal thead th {
      box-shadow: 0 1px 0 0 var(--hsl-grey-lighter);
      background: var(--hsl-white);
    }
    tbody {
      tr:nth-child(1) {
        th, td { border-width: 0; }
      }
    }
  }

  tbody {
    th, td {
      border-style: solid;
      border-color: var(--hsl-grey-lighter);
      border-width: 1px 0;
    }
  }



  table {
    &.white-space-normal {
      th, td { white-space: normal; }
    }
    &.white-space-nowrap {
      th, td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  table {
    border-collapse: collapse;
    
    th {
      font-family: var(--font-family-bold);
      font-weight: var(--font-weight-bold);
      text-align: left;
    }

    &.size-xs {
      font-size: var(--font-size-xs);
      th, td { padding: 3px 6px; }
    }
    &.size-s {
      font-size: var(--font-size-s);
      th, td { padding: 4px 8px; }
    }
    &.size-m {
      font-size: var(--font-size-m);
      th, td { padding: 6px 10px; }
    }
    &.size-l {
      font-size: var(--font-size-l);
      th, td { padding: 10px 15px; }
    }

  }

  tbody tr:nth-of-type(odd) { background-color: var(--hsl-white); }
  tbody tr:nth-of-type(even) { background-color: var(--hsl-background); }

}



.table-container {
  &.scroll-snap {
    scroll-snap-type: y mandatory;
    tbody tr {
      scroll-snap-align: start;
      scroll-margin-top: -1px;
    }
  }
}