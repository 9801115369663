// Buttons

button {
	-webkit-tap-highlight-color: transparent;
	-webkit-appearance: none;
	appearance: none;
	cursor: pointer;
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
  border: 1px solid transparent;
  text-align: center;
  box-shadow: 0 0 0 transparent;
  transition: all .15s ease;
  font-size: var(--font-size-xs);
  font-family: var(--font-family-bold);
  font-weight: var(--font-weight-bold);

  &.hide {
    pointer-events: none;
    opacity: 0;
  }

  &:disabled {
    pointer-events: none;
    opacity: .35;
  }

}



// Button Shapes

button {
  background-color: black;
  color: var(--hsl-white);

  &.shape-square { border-radius: 0; }
  &.shape-rounded { border-radius: 3px; }
  &.shape-circle { border-radius: 99px; }

}



// Button Sizes

button {

  &.size-s {
    min-width: 24px;
    min-height: 24px;
    height: 24px;
    padding: 0 3px;
    font-size: var(--font-size-xxs);
    span, label {
      padding: 0 2px;
    }
  }

  &.size-m {
    min-width: 32px;
    min-height: 32px;
    height: 32px;
    padding: 0 4px;
    font-size: var(--font-size-xs);
    span, label {
      padding: 0 4px;
    }
  }

  &.size-l {
    min-width: 40px;
    min-height: 40px;
    height: 40px;
    padding: 0 5px;
    font-size: var(--font-size-s);
    span, label {
      padding: 0 5px;
    }
  }

  &.size-xl {
    min-width: 48px;
    min-height: 48px;
    height: 48px;
    padding: 0 6px;
    font-size: var(--font-size-m);
    span, label {
      padding: 0 5px;
    }
  }

  &.size-responsive {
    min-width: 32px;
    min-height: 32px;
    height: 32px;
    padding: 0 4px;
    font-size: var(--font-size-xs);
    span, label {
      padding: 0 4px;
    }

    @media screen and (min-width: 768px) {
      min-width: 40px;
      min-height: 40px;
      height: 40px;
      padding: 0 5px;
      font-size: var(--font-size-s);
      span, label {
        padding: 0 5px;
      }
    }
  }

}



// Button Variants / Colors / Icons

button {

  .icon {
    pointer-events: none;
    padding: 3px;
  }

  &.icon-position-left { flex-direction: row; }
  &.icon-position-right { flex-direction: row-reverse; }

  &.variant-flat {

    .icon {
      &.variant-secondary svg { fill: var(--hsl-white); }
      &.variant-flat { background-color: var(--hsl-white); }
      &.variant-ghost {
        box-shadow: inset 0 0 0 1px var(--hsl-white);
        svg { fill: var(--hsl-white);}
      }
    }

    &.color-primary {
      background-color: var(--hsl-theme-primary);
      .icon.variant-flat svg { fill: var(--hsl-theme-primary); }
      &:hover { filter: brightness(85%); }
    }
    &.color-default {
      background-color: var(--hsl-text);
      .icon.variant-flat svg { fill: var(--hsl-text); }
      &:hover { filter: brightness(85%); }
    }
    &.color-red {
      background-color: var(--hsl-red);
      .icon.variant-flat svg { fill: var(--hsl-red); }
      &:hover { filter: brightness(85%); }
    }
    &.color-orange {
      background-color: var(--hsl-orange);
      .icon.variant-flat svg { fill: var(--hsl-orange); }
      &:hover { filter: brightness(85%); }
    }
    &.color-green {
      background-color: var(--hsl-green);
      .icon.variant-flat svg { fill: var(--hsl-green); }
      &:hover { filter: brightness(85%); }
    }
    &.color-grey {
      color: var(--hsl-grey-darker);
      background-color: var(--hsl-grey-lighter);
      .icon.variant-flat svg { fill: var(--hsl-grey-darker); }
      .icon.variant-secondary svg { fill: var(--hsl-grey-darker); }
      .icon.variant-ghost {
        box-shadow: inset 0 0 0 1px var(--hsl-grey-darker);
        svg { fill: var(--hsl-grey-darker); }
      }
      &:hover {
        background-color: var(--hsl-grey-light);
      }
    }
  }
  


  &.variant-ghost {
    background-color: var(--hsl-white);

    &:hover {
      .icon svg { fill: var(--hsl-white); }
      .icon.variant-ghost { box-shadow: inset 0 0 0 1px var(--hsl-white); }
      .icon.variant-flat {
        &.size-s  { box-shadow: inset 0 0 0 8px var(--hsl-white); }
        &.size-m  { box-shadow: inset 0 0 0 10px var(--hsl-white); }
        &.size-l  { box-shadow: inset 0 0 0 12px var(--hsl-white); }
        &.size-xl { box-shadow: inset 0 0 0 16px var(--hsl-white); }
      }
    }

    &.color-primary {
      border-color: var(--hsl-theme-primary);
      color: var(--hsl-theme-primary);
      &:hover {
        background-color: var(--hsl-theme-primary);
        color: var(--hsl-white);
        .icon.variant-flat svg { fill: var(--hsl-theme-primary); }
      }
    }
    &.color-default {
      border-color: var(--hsl-text);
      color: var(--hsl-text);
      &:hover {
        background-color: var(--hsl-text);
        color: var(--hsl-background);
        .icon.variant-flat svg { fill: var(--hsl-text); }
      }
    }
    &.color-red {
      border-color: var(--hsl-red);
      color: var(--hsl-red);
      &:hover {
        background-color: var(--hsl-red);
        color: var(--hsl-white);
        .icon.variant-flat svg { fill: var(--hsl-red); }
      }
    }
    &.color-orange {
      border-color: var(--hsl-orange);
      color: var(--hsl-orange);
      &:hover {
        background-color: var(--hsl-orange);
        color: var(--hsl-white);
        .icon.variant-flat svg { fill: var(--hsl-orange); }
      }
    }
    &.color-green {
      border-color: var(--hsl-green);
      color: var(--hsl-green);
      &:hover {
        background-color: var(--hsl-green);
        color: var(--hsl-white);
        .icon.variant-flat svg { fill: var(--hsl-green); }
      }
    }
    &.color-grey {
      border-color: var(--hsl-grey-lighter);
      color: var(--hsl-grey-darker);
      .icon.variant-flat { background-color: var(--hsl-grey-darker); }
      .icon.variant-secondary svg { fill: var(--hsl-grey-darker); }
      .icon.variant-ghost {
        box-shadow: inset 0 0 0 1px var(--hsl-grey-darker);
        svg { fill: var(--hsl-grey-darker); }
      }
      &:hover {
        background-color: var(--hsl-grey-lighter);
        .icon.variant-flat svg { fill: var(--hsl-grey-darker); }
      }
    }
  }



  &.variant-secondary {
    background-color: transparent;
    color: black;

    &:hover {
      background: black;
      color: var(--hsl-white);
      .icon svg { fill: var(--hsl-white); }
      .icon.variant-ghost { box-shadow: inset 0 0 0 1px var(--hsl-white); }
      .icon.variant-flat {
        &.size-s  { box-shadow: inset 0 0 0 8px var(--hsl-white); }
        &.size-m  { box-shadow: inset 0 0 0 10px var(--hsl-white); }
        &.size-l  { box-shadow: inset 0 0 0 12px var(--hsl-white); }
        &.size-xl { box-shadow: inset 0 0 0 16px var(--hsl-white); }
      }
    }

    &.color-primary {
      color: var(--hsl-theme-primary);
      &:hover {
        background-color: var(--hsl-theme-primary);
        color: var(--hsl-white);
        .icon.variant-flat svg { fill: var(--hsl-theme-primary); }
      }
    }
    &.color-default {
      color: var(--hsl-text);
      &:hover {
        background-color: var(--hsl-text);
        color: var(--hsl-white);
        .icon.variant-flat svg { fill: var(--hsl-text); }
      }
    }
    &.color-red {
      color: var(--hsl-red);
      &:hover {
        background-color: var(--hsl-red);
        color: var(--hsl-white);
        .icon.variant-flat svg { fill: var(--hsl-red); }
      }
    }
    &.color-orange {
      color: var(--hsl-orange);
      &:hover {
        background-color: var(--hsl-orange);
        color: var(--hsl-white);
        .icon.variant-flat svg { fill: var(--hsl-orange); }
      }
    }
    &.color-green {
      color: var(--hsl-green);
      &:hover {
        background-color: var(--hsl-green);
        color: var(--hsl-white);
        .icon.variant-flat svg { fill: var(--hsl-green); }
      }
    }
    &.color-grey {
      color: var(--hsl-grey-darker);
      .icon.variant-flat { background-color: var(--hsl-grey-darker); }
      .icon.variant-secondary svg { fill: var(--hsl-grey-darker); }
      .icon.variant-ghost {
        box-shadow: inset 0 0 0 1px var(--hsl-grey-darker);
        svg { fill: var(--hsl-grey-darker); }
      }
      &:hover {
        background-color: var(--hsl-grey-lighter);
        .icon.variant-flat svg { fill: var(--hsl-grey-darker); }
      }
    }
  }

}



// Upload Button

.upload-button-container {
  display: inline-flex;

  + ul {
    font-size: var(--font-size-xs);
    list-style-type: none;
    &:not(:empty) {
      margin: 5px 0 10px;
    }
  }
}

.upload-button {
  position: relative;
}

input[type=file] {
	-webkit-tap-highlight-color: transparent;
	cursor: pointer;
	box-shadow: none;
	border: 0;
	padding: 0;

	&.upload {
		z-index: 1;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
    opacity: 0;
    cursor: pointer;

		+ button {
      width: 100%;
      border-style: dashed;
      &.dragover {
        border-color: var(--hsl-theme-primary);
        background-color: hsla( var(--theme-primary-h), var(--theme-primary-s), var(--theme-primary-l), 35% );
      }
		}

		&:hover:not(:disabled) + button {
      &.variant-ghost {
        color: var(--hsl-white);
        background-color: var(--hsl-theme-primary);
        .icon.variant-flat svg { fill: var(--hsl-theme-primary); }
        .icon.variant-secondary svg { fill: var(--hsl-white); }
        .icon.variant-ghost {
          box-shadow: inset 0 0 0 1px var(--hsl-white);
          svg { fill: var(--hsl-white); }
        }
        .icon.variant-flat {
          &.size-s  { box-shadow: inset 0 0 0 8px var(--hsl-white); }
          &.size-m  { box-shadow: inset 0 0 0 10px var(--hsl-white); }
          &.size-l  { box-shadow: inset 0 0 0 12px var(--hsl-white); }
          &.size-xl { box-shadow: inset 0 0 0 16px var(--hsl-white); }
        }
      }
      &.variant-flat { background-color: hsl( var(--theme-primary-h), var(--theme-primary-s), calc(var(--theme-primary-l) * .85) ); }
    }
    &:focus + button {
      outline: auto;
    }
    &:focus:not(:focus-visible) + button {
      outline: none;
    }
    &:required + button label::after { content: ' *'; }
    &:disabled { cursor: not-allowed; }
	}
}



.show-invalid {

	input[type=file].upload:invalid {

    + button {
      color: var(--hsl-red);
      border-color: var(--hsl-red);
      .icon { background: var(--hsl-red); }
    }

    &:hover + button {
      color: var(--hsl-white);
      border-color: var(--hsl-theme-primary);
    }      
	}
}
