// Tooltips

.tooltip-container {
  position: absolute;
  display: flex;
  width: calc(100% - 8px);
  margin: 8px;

  .icon:hover + .tooltip {
    opacity: 1;
  }

  .tooltip {
    pointer-events: none;
    opacity: 0;
    z-index: 1;
    position: absolute;
    border-radius: 5px;
    padding: 12px;
    background-color: var(--hsl-text);
    color: var(--hsl-white);
    transition: opacity .3s ease-out;

    &::before {
      content: '';
      position: absolute;
      height: 0;
      width: 0;
      border: 8px solid transparent;
    }
  }
}



// Tooltip positioning

.tooltip-container {

  &.pos-top-left {
    top: 0;
    left: 0;
    .tooltip {
      margin-bottom: 8px;
      bottom: 36px;
      left: -8px;
      &::before {
        border-top-color: var(--hsl-text);
        top: 100%;
        left: 12px;
      }
    }
  }

  &.pos-top-right {
    justify-content: flex-end;
    top: 0;
    right: 0;
    .tooltip {
      margin-bottom: 8px;
      bottom: 36px;
      right: -8px;
      &::before {
        border-top-color: var(--hsl-text);
        top: 100%;
        right: 12px;
      }
    }
  }

  &.pos-bottom-left {
    bottom: 0;
    left: 0;
    .tooltip {
      margin-top: 8px;
      top: 36px;
      left: -8px;
      &::before {
        border-bottom-color: var(--hsl-text);
        bottom: 100%;
        left: 12px;
      }
    }
  }

  &.pos-bottom-right {
    justify-content: flex-end;
    bottom: 0;
    right: 0;
    .tooltip {
      margin-top: 8px;
      top: 36px;
      right: -8px;
      &::before {
        border-bottom-color: var(--hsl-text);
        bottom: 100%;
        right: 12px;
      }
    }
  }

}
