.loading-spinner {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  &.mask {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    background: var(--hsl-white);
    opacity: .85;
  }
}

@keyframes spin {
	0%    { transform: rotate(0deg); }
	100%  { transform: rotate(360deg); }
}

.spin { animation: spin 1s infinite linear; }