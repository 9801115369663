// body.disable-scrolling {
//   overflow: hidden;
// }

@keyframes enterModal {
	0%    { opacity: 0; }
	100%  { opacity: 1; }
}
@keyframes exitModal {
	0%    { opacity: 1; }
	100%  { opacity: 0; }
}

@keyframes enterModalContent {
	0%    { transform: translateY(16px); }
	100%  { transform: translateY(0); }
}
@keyframes exitModalContent {
	0%    { transform: translateY(0); }
	100%  { transform: translateY(-16px); }
}


.modal-container {
  overflow: auto;
  z-index: 8;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  background: rgba(black,.8);

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    background: var(--hsl-white);
    min-width: 320px;
    max-width: 720px;
    max-height: 90vh;
    border-radius: 9px;
    overflow: hidden;

    header {
      color: var(--hsl-text);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 12px 48px 12px 24px;
      > button {
        position: absolute;
        right: 0;
        top: 0;
        margin: 8px;
      }
    }

    main {
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: auto;
      padding: 16px 24px 12px;
    }
  }
}