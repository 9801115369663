.tab-nav {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 0 4px;
  border-radius: 7px;
  overflow: hidden;
	background-color: var(--hsl-white);
  box-shadow: 0 1px 4px HSLA( var(--theme-primary-h), 100%, 17%, .12 );
  padding: 0 4px;

  @media screen and (min-width: 768px) {
    padding: 0 8px;
  }

  > button {
    border: none;
    background-color: transparent;
    height: 48px;
    font-size: var(--font-size-m);
    color: var(--hsl-grey-light);
    padding: 0 16px;

    > span {
      display: flex;
      align-items: center;
      height: 100%;
      transition: box-shadow .25s ease;
    }
    
    &:hover { color: var(--hsl-theme-primary); }
    
    &.active {
      color: var(--hsl-theme-primary);
      span { box-shadow: inset 0 -3px 0 0 var(--hsl-theme-primary); }
    }
  }

}