.flex-container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;

  .column {
    width: 100%;
    &.size-full-width { min-width: 100%; }
  }

  .row {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  

  &.responsive {

    > .row { flex-direction: column; }  

    &.column-size-s > .row {
      @media screen and (min-width: 768px) { flex-direction: row; }
    }
    &.column-size-m > .row {
      @media screen and (min-width: 1024px) { flex-direction: row; }
    }
    &.column-size-l > .row {
      @media screen and (min-width: 1280px) { flex-direction: row; }
    }
    
  }

  &, .row {

    &.align-items-stretch { align-items: stretch; }
    &.align-items-center { align-items: center; }
    &.align-items-flex-start { align-items: flex-start; }
    &.align-items-flex-end { align-items: flex-end; }
    &.align-items-baseline { align-items: baseline; }

    &.justify-content-center { justify-content: center; }
    &.justify-content-flex-start { justify-content: flex-start; }
    &.justify-content-flex-end { justify-content: flex-end; }
    &.justify-content-space-between { justify-content: space-between; }
    &.justify-content-space-around { justify-content: space-around; }
    &.justify-content-space-evenly { justify-content: space-evenly; }

  }
}



// Auto width

.flex-container.variant-auto-width {
  > .column,
  .row > .column {
    max-width: max-content; // doesn't work in IE11
  }
}



// Max width

.flex-container.variant-max-width {

  @media screen and (min-width: 768px) {
    &.column-size-s > .column { max-width: 300px; }
    &.column-size-m > .column { max-width: 360px; }
    &.column-size-l > .column { max-width: 400px; }
  }
}



// Fluid width

.flex-container.variant-fluid-width {

  &.column-size-s > .column,
  &.column-size-s > .row > .column {
    @media screen and (min-width: 768px) { width: 50%; }
    @media screen and (min-width: 1280px) { width: 33.333%; }
    @media screen and (min-width: 1600px) { width: 25%; }
    @media screen and (min-width: 2400px) { width: 20%; }
  }
  &.column-size-m > .column,
  &.column-size-m > .row > .column {
    @media screen and (min-width: 1024px) { width: 50%; }
    @media screen and (min-width: 1600px) { width: 33.333%; }
    @media screen and (min-width: 2048px) { width: 25%; }
    @media screen and (min-width: 2880px) { width: 20%; }
  }
  &.column-size-l > .column,
  &.column-size-l > .row > .column {
    @media screen and (min-width: 1280px) { width: 50%; }
    @media screen and (min-width: 2048px) { width: 33.333%; }
    @media screen and (min-width: 2560px) { width: 25%; }
    @media screen and (min-width: 3200px) { width: 20%; }
  }
}



// Custom Width

.flex-container.variant-custom-width {

  &.column-size-s .column > .column:not(:last-child) {
    margin-bottom: 8px;
    @media screen and (min-width: 768px) { margin-bottom: 16px; }
  }
  &.column-size-m .column > .column:not(:last-child) {
    margin-bottom: 24px;
    @media screen and (min-width: 768px) { margin-bottom: 32px; }
  }
  &.column-size-l .column > .column:not(:last-child) {
    margin-bottom: 32px;
    @media screen and (min-width: 768px) { margin-bottom: 48px; }
  }

  &:not(.responsive) {
    .column.size-10 { width: 10%; }
    .column.size-20 { width: 20%; }
    .column.size-30 { width: 30%; }
    .column.size-40 { width: 40%; }
    .column.size-50 { width: 50%; }
    .column.size-60 { width: 60%; }
    .column.size-70 { width: 70%; }
    .column.size-80 { width: 80%; }
    .column.size-90 { width: 90%; }
    .column.size-100 { width: 100%; }
  }

  &.responsive {

    &.column-size-s > .column,
    &.column-size-s > .row > .column {
      @media screen and (min-width: 768px) {
        &.size-10 { width: 10%; }
        &.size-20 { width: 20%; }
        &.size-30 { width: 30%; }
        &.size-40 { width: 40%; }
        &.size-50 { width: 50%; }
        &.size-60 { width: 60%; }
        &.size-70 { width: 70%; }
        &.size-80 { width: 80%; }
        &.size-90 { width: 90%; }
        &.size-100 { width: 100%; }
      }
    }

    &.column-size-m > .column,
    &.column-size-m > .row > .column {
      @media screen and (min-width: 1024px) {
        &.size-10 { width: 10%; }
        &.size-20 { width: 20%; }
        &.size-30 { width: 30%; }
        &.size-40 { width: 40%; }
        &.size-50 { width: 50%; }
        &.size-60 { width: 60%; }
        &.size-70 { width: 70%; }
        &.size-80 { width: 80%; }
        &.size-90 { width: 90%; }
        &.size-100 { width: 100%; }
      }
    }
    
    &.column-size-l > .column,
    &.column-size-l > .row > .column {
      @media screen and (min-width: 1280px) {
        &.size-10 { width: 10%; }
        &.size-20 { width: 20%; }
        &.size-30 { width: 30%; }
        &.size-40 { width: 40%; }
        &.size-50 { width: 50%; }
        &.size-60 { width: 60%; }
        &.size-70 { width: 70%; }
        &.size-80 { width: 80%; }
        &.size-90 { width: 90%; }
        &.size-100 { width: 100%; }
      }
    }
  }
}



// Gutter size

.flex-container {

  &.gutter-size-s {
    > .row > .column,
    > .column {
      padding: 4px;
    }
    @media screen and (min-width: 768px) {
      margin: 0 -8px;
      > .row > .column,
      > .column {
        padding: 8px;
      }
    }
  }

  &.gutter-size-m {
    > .row > .column,
    > .column {
      padding: 12px;
    }
    @media screen and (min-width: 768px) {
      margin: 0 -16px;
      > .row > .column,
      > .column {
        padding: 16px;
      }
    }
  }

  &.gutter-size-l {
    > .row > .column,
    > .column {
      padding: 16px;
    }
    @media screen and (min-width: 768px) {
      margin: 0 -24px;
      > .row > .column,
      > .column {
        padding: 24px;
      }
    }
  }

}